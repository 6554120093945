require('./styles/index.scss');

import '@fortawesome/fontawesome-free/js/all'

import hljs from 'highlight.js/lib/highlight';
import javascript from 'highlight.js/lib/languages/javascript';
import php from 'highlight.js/lib/languages/php';
import java from 'highlight.js/lib/languages/java';
import golang from 'highlight.js/lib/languages/go';
import bash from 'highlight.js/lib/languages/bash';
import json from 'highlight.js/lib/languages/json';

import 'highlight.js/styles/github.css';

import Typed from 'typed.js';

hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('php', php);
hljs.registerLanguage('java', java);
hljs.registerLanguage('golang', golang);
hljs.registerLanguage('bash', bash);
hljs.registerLanguage('json', json);

// kickstart highlight.js
if( document.readyState !== 'loading' ) {
  initHLJS();
} else {
  document.addEventListener('DOMContentLoaded', (event) => initHLJS());
}

/**
 * Initialize highlightjs
 */
function initHLJS() {
  document.querySelectorAll('pre code').forEach((block) => {
    block.className = block.className.replace('language-', 'hljs ');
    hljs.highlightBlock(block);
  });
}
/**
 * Navbar responsiveness
 */
document.addEventListener('DOMContentLoaded', () => {
  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {
    // Add a click event on each of them
    $navbarBurgers.forEach( el => {
      el.addEventListener('click', () => {
        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);
        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle('is-active');
        $target.classList.toggle('is-active');
      });
    });
  }

  // kick type.js
  new Typed('#typed', {
    stringsElement: '#typed-strings',
    typeSpeed: 50,
    fadeOut: true,
  });
});

